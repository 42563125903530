import Vue from 'vue'
import VueRouter from 'vue-router'
import loginIndex from '../components/loginIndex.vue'
import homeIndex from '../components/homeIndex.vue'

Vue.use(VueRouter)


const routes = [
    { path: '/', redirect: '/login', meta: { title: '登录-管理中心' } },
    { path: '/login', component: loginIndex, meta: { title: '登录-管理中心' } },
    {
        path: '/home',
        component: homeIndex,
        meta: { title: '管理中心' }, children: [
            { path: '/home', redirect: '/home/index', meta: { title: '管理中心' } },
            { path: '/home/index', component: () => import('@/components/index.vue'), meta: { title: '管理中心' } },
            { path: '/systemInfo', component: () => import('@/components/set/system.vue'), meta: { title: '系统设置' } },
            { path: '/adminInfo', component: () => import('@/components/set/adminInfo.vue'), meta: { title: '管理员设置' } },
            { path: '/ubmission', component: () => import('@/components/dataSet/ubmission.vue'), meta: { title: '人员类别' } },
            { path: '/module', component: () => import('@/components/dataSet/module.vue'), meta: { title: '模块数据' } },
            { path: '/project_content', component: () => import('@/components/dataSet/project_content.vue'), meta: { title: '培训数据' } },
            { path: '/registration/info', component: () => import('@/components/info.vue'), meta: { title: '报名详情' } },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//路由守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    if (to.path === '/login') {
        const token = localStorage.getItem('adminToken');
        const time = Number(localStorage.getItem('tokenStartTime'))
        if (token && time + (7 * 24 * 60 * 60) - (60 * 60) > Math.round(new Date().getTime() / 1000)) return next('/home')
        return next()
    }
    if (to.path) {
        const token = localStorage.getItem('adminToken');
        const time = Number(localStorage.getItem('tokenStartTime'));
        if (token && time + (7 * 24 * 60 * 60) - (60 * 60) > Math.round(new Date().getTime() / 1000)) return next()
        return next('/login')
    }
    next()
})

export default router
