import Vue from 'vue'
import {
    form, FormItem, Input, Button, Message, Loading, Container, Header, Aside, Main,
    DropdownItem, DropdownMenu, Dropdown, Dialog, Menu, Submenu, MenuItem, Table, TableColumn, Tag,
    Pagination, MessageBox, Drawer, DatePicker, Switch, Upload, Divider, Autocomplete

} from 'element-ui'
Vue.use(Button)
Vue.use(form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Dropdown)
Vue.use(Dialog)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(Drawer)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Upload)
Vue.use(Divider)
Vue.use(Autocomplete)
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
