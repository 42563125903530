<template>
    <div>
        <div class="outer_box">
            <div class="back_one"></div>
            <div class="back_two"></div>
            <div class="login_box">
                <h3 class="title">登录系统</h3>
                <p><i class="el-icon-user"></i><input type="text" v-model="info.username" placeholder="登录账号"></p>
                <p><i class="el-icon-bell"></i><input type="password" v-model="info.password" placeholder="登录密码"></p>
                <p class="logIn_btn" @click="login">
                    <span class="left_line"></span>
                    <span>登录</span>
                    <span class="right_line"></span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            info: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        login() {
            if (this.info.username == '' || this.info.password == '') {
                this.$message({
                    message: '账号或密码不能为空',
                    type: 'error'
                })
                return
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            this.$http.post('/admin/login', this.info).then(res => {
                loading.close()
                if (res.data.code === 200) {
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    })
                    var timestamp = Math.round(new Date().getTime() / 1000).toString()
                    localStorage.setItem('tokenStartTime', timestamp)
                    localStorage.setItem('adminToken', 'Bearer ' + res.data.token)

                    this.$router.push('/home')
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
* {
    margin: 0px;
    padding: 0px;
}

body {
    background-color: wheat;
}

input {
    border: none;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 0px 5px 15px;
    margin-left: 15px;
    font-size: 15px;
    color: #000000;
    outline: none;
}

.outer_box {
    position: relative;
    height: calc(100vh);
}

/* 背景颜色 */
.back_one {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0px;
    background-color: #7BB6B6;
}

.back_two {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0px;
    background-color: #E3D0AD;
}

/* 登录窗口 */
.login_box {
    width: 500px;
    height: 350px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px #80808079;
}

/* 标题 */
.login_box .title {
    width: 100%;
    text-align: center;
    margin: 35px 0px 35px 0px;
    font-size: 30px;
    color: #52565B;
}

/* p标签的公共样式 */
.login_box p {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    text-align: center;
    justify-content: center;
}



/* 登录按钮的样式 */
.login_box .logIn_btn {
    width: 250px;
    height: 40px;
    line-height: 40px;
    margin: 0 auto;
    background-color: #EBF7F3;
    color: #7BB6B6;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #e6e5e5;
    font-weight: bold;
    display: flex;
    margin-top: 30px;
    cursor: pointer;
}

.login_box .logIn_btn span {
    display: block;
    width: 33%;
    /* height: 100%; */
}

.login_box .logIn_btn .left_line {
    height: 20px;
    margin-top: 20px;
    border-left: 2px solid #7BB6B6;
}

.login_box .logIn_btn .right_line {
    height: 20px;
    border-right: 2px solid #7BB6B6;
}

/* 注册按钮 */
.login_box .login {
    width: 100px;
    height: 40px;
    margin: 20px 0px 0px 100px;
    color: #E3D0AD;
    font-weight: bold;
    line-height: 40px;
}
</style>></style>